<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  {{ companyData.companies_id }} - {{ companyData.companies_name }} -> Info
                  <a :href='`${SITE_ROOT}/companies/${companyData.companies_id}/schedules`' target='_blank'>WA</a>
                </div>
                <!-- <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  <a :href='`${WEB_ROOT}/company/${companyId}/performance`' target='_blank' class="highlight">
                    performance
                  </a>
                </div> -->
                <!-- <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  <a :href='`${WEB_ROOT}/company/${companyId}/invoices`' target='_blank' class="highlight">
                    invoices
                  </a>
                </div> -->
                <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  <a :href='`${WEB_ROOT}/company/${companyId}/users`' target='_blank' class="highlight">
                    users
                  </a>
                </div>
                <!-- <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  <a :href='`${WEB_ROOT}/company/${companyId}/emailTemplate`' target='_blank' class="highlight">
                    emailTemplate
                  </a>
                </div> -->
                <!-- <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  <a :href='`${WEB_ROOT}/company/${companyId}/bigchecker`' target='_blank' class="highlight">
                    bigchecker
                  </a>
                </div> -->
                <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  <a :href='`${WEB_ROOT}/company/${companyId}/schedulePermissions`' target='_blank' class="highlight">
                    permissions
                  </a>
                </div>
              </div>
            </div>
        </div>
      </div>
    
      
      
        
      <div class="columns is-marginless">
        <div class="column is-12 is-gapless is-centered is-multiline">
          <h1>periods:</h1>
          <b-button v-for="(period) in periods" v-bind:key="`${period.company_periods_id}_index`" 
              class="button m1rem" 
              :class="periodFilteredSet.size === 0 || periodFilteredSet.has(period.company_periods_id) ? 'is-primary' : ''"
              @click="toggleFilterPeriod(period.company_periods_id)">
            <strong>{{ period.company_periods_id }}-{{ period.company_periods_name }}</strong>
          </b-button>
        </div>
      </div>
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Schedules</h1>
        <b-table
            :data="scheduleDataFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableScheduleIsLoading"
            :mobile-cards="true">

            <b-table-column field="id" label="scheduleId" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" label="name" width="100" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.name }}
            </b-table-column>
            
            <b-table-column field="start_date" label="start_date" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{ DDMMYYYY(new Date(props.row.start_date)) }}
            </b-table-column>

            <b-table-column field="end_date" label="end_date" width="40" :td-attrs="columnTdAttrs" date v-slot="props" sortable centered searchable>
                {{ props.row.end_date ? DDMMYYYY(new Date(props.row.end_date)) : '-' }}
            </b-table-column>
            
            <b-table-column field="company_period_id" label="period" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.company_period_id }} - {{ props.row.company_periods_name }}
            </b-table-column>
            
            <b-table-column field="is_active" label="active" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.is_active }}
            </b-table-column>
            
            <b-table-column field="max_phase_id" label="max_phase_id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.max_phase_id }} - {{ props.row.phaseInfo.map((phase) => { return phase.name }).join(` - `) }}
            </b-table-column>
            
            <b-table-column field="allowedUsers" label="Allowed" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                <div v-for="(allowedUsers) in props.row.allowedUsers" v-bind:key="`${allowedUsers.roles_id}_index`">
                  {{ allowedUsers.roles_name }}
                </div>                
            </b-table-column>
            
            <b-table-column field="phaseResponseStatsPerStatus" label="phase responses stats" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                <div v-for="(responseStatus) in props.row.phaseResponseStatsPerStatus" v-bind:key="`${responseStatus.status_id}_index`">
                  {{ responseStatus.key }}: {{ responseStatus.total }}
                </div>                
                <div>--------------</div>
                <div>
                  Total Users: {{ props.row.phaseResponseStatsPerUser.length }}
                </div>
            </b-table-column>
            
            
            <b-table-column field="max_blocked_days" label="max_blocked_days" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.max_blocked_days }}
            </b-table-column>
            
            <b-table-column field="deleted_at" label="deleted_at" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered>
                {{ props.row.deleted_at ? DDMMYYYY(new Date(props.row.deleted_at)) : '-' }}                
            </b-table-column>
            
            <b-table-column field="show" label="actions" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" centered>
                <b-button @click="toggleShowAllScheduleCodes(props.row.id, true)" :type="''">Show codes</b-button>
            </b-table-column>
            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      <div v-if="false" class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Roles</h1>
        <b-switch v-model="showRolesTable"> {{ showRolesTable ? 'Hide' : 'Show' }} </b-switch>
        <div v-show="showRolesTable" class="columns is-gapless is-multiline m2rem">
          <div class="column is-1" v-for="(role) in companyRolesButtons" v-bind:key="`${role.id}_index`">
            <b-button @click="toggleShowRoleStatus(role)" :type="role.show === true ? 'is-success' : ''">
              {{  role.name }}
            </b-button>
          </div>
        </div>
        <b-table
            v-show="showRolesTable"
            :data="roleDataFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableRolesIsLoading"
            :columns="rolesColumnData"
            :mobile-cards="true">

            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      
      <div v-if="false" class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Holidays</h1>
        <b-switch v-model="showHolidayData"> {{ showHolidayData ? 'Hide' : 'Show' }} </b-switch>
        <b-table
            v-show="showHolidayData"
            :data="holidayData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableCodesIsLoading"
            :paginated="holidayDataIsPaginated"
            :per-page="holidayDataIsPaginatedPerPage"
            :current-page.sync="holidayDataCurrentPage"
            :mobile-cards="true">

            <b-table-column field="wa_company_holidays_company_id" label="company id" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ props.row.wa_company_holidays_company_id }}
            </b-table-column>
            <b-table-column field="wa_company_holiday_codes_code_id" label="code id" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ props.row.wa_company_holiday_codes_code_id }}
            </b-table-column>
            <b-table-column field="wa_company_holiday_codes_hourly_rate" label="hourly rate" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.wa_company_holiday_codes_hourly_rate }}
            </b-table-column>
            
            <b-table-column field="wa_company_holidays_name" label="name" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.wa_company_holidays_name }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_type" label="type" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ props.row.wa_company_holidays_type }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_start_datetime" label="start datetime" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.wa_company_holidays_start_datetime)) }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_end_datetime" label="end datetime" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.wa_company_holidays_end_datetime)) }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_year" label="holidays year" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.wa_company_holidays_year }}
            </b-table-column>            
            
            <b-table-column field="wa_company_holiday_codes_created_at" label="created at" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.wa_company_holiday_codes_created_at)) }}
            </b-table-column>
            <b-table-column field="wa_company_holiday_codes_updated_at" label="updated at" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.wa_company_holiday_codes_updated_at)) }}
            </b-table-column>
            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Codes</h1>
        <b-button @click="showAllCodes()">ShowAll</b-button>
        <b-button @click="hideAllCodes()">HideAll</b-button>
        <b-switch v-model="showCodesTable"> {{ showCodesTable ? 'Hide' : 'Show' }} </b-switch>
        <b-table
            v-show="showCodesTable"
            :data="codesDataFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableCodesIsLoading"
            :mobile-cards="true">

            <b-table-column field="wa_code_schedule_schedule_id" label="schedule Id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_schedule_schedule_id }}
            </b-table-column>
            
            <b-table-column field="wa_code_schedule_id" label="code schedule Id" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ props.row.wa_code_schedule_id }}
            </b-table-column>

            <b-table-column field="wa_code_schedule_code_id" label="code Id" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ props.row.wa_code_schedule_code_id }}
            </b-table-column>
            
            
            
            <b-table-column field="wa_codes_code" label="code" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.wa_codes_code }}
            </b-table-column>
            <b-table-column field="wa_codes_name" label="name" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.wa_codes_name }}
            </b-table-column>
            <b-table-column field="wa_codes_type" label="type" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.wa_codes_type }}
            </b-table-column>
            
            <b-table-column field="wa_code_schedule_start_date" label="start" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{  props.row.wa_codes_start_time ? props.row.wa_codes_start_time.substr(0,5) : '-' }}
            </b-table-column>
            <b-table-column field="wa_code_schedule_end_date" label="end" width="40" :td-attrs="columnTdAttrs" date v-slot="props" sortable centered searchable>
                {{  props.row.wa_codes_end_time ? props.row.wa_codes_end_time.substr(0,5) : '-' }}
            </b-table-column>
            
            <b-table-column field="wa_codes_price" label="price" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{ props.row.wa_codes_price ? `&euro;${props.row.wa_codes_price}` : '-' }}
            </b-table-column>
            
            <b-table-column field="wa_codes_registration_start_time" label="reg time start" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{ props.row.wa_codes_registration_start_time ? props.row.wa_codes_registration_start_time.substr(0,5) : '' }}
            </b-table-column>
            <b-table-column field="wa_codes_registration_end_time" label="reg time end" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{ props.row.wa_codes_registration_end_time ? props.row.wa_codes_registration_end_time.substr(0,5) : '' }}
            </b-table-column>
            

            <b-table-column field="wa_code_schedule_exclude_quotum" label="exclude quotum" width="40" :td-attrs="columnTdAttrs" date v-slot="props" string sortable centered searchable>
                {{ props.row.wa_code_schedule_exclude_quotum }}
            </b-table-column>
            <b-table-column field="wa_code_schedule_quotum" label="quotem hours" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_schedule_quotum !== null ? props.row.wa_code_schedule_quotum : 0 }}
            </b-table-column>
            
            <b-table-column field="wa_code_schedule_exclude_min_max" label="exclude min max" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_schedule_exclude_min_max }}
            </b-table-column>
            <b-table-column field="wa_code_min_max_hours" label="min max" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_min_max_hours }}
            </b-table-column>
            <b-table-column field="wa_code_cost_header" label="cost header" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_cost_header }}
            </b-table-column>
            
            
            
            <b-table-column field="wa_code_schedule_ignore_time_gap" label="ignore time gap" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_schedule_ignore_time_gap === 1 ? "1" : "0" }}
            </b-table-column>
            
            <b-table-column field="wa_code_schedule_grouped_code" label="grouped code" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_schedule_grouped_code }}
            </b-table-column>
            
            
            <b-table-column field="stats.openCount" label="open" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.stats.openCount }}
            </b-table-column>
            <b-table-column field="stats.openHours" label="open Hours" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ roundMaxDigits(props.row.stats.openHours) }}
            </b-table-column>            
            
            <b-table-column field="stats.assignedCount" label="assigned" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.stats.assignedCount }}
            </b-table-column>
            <b-table-column field="stats.assignedHours" label="assigned Hours" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ roundMaxDigits(props.row.stats.assignedHours) }}
            </b-table-column>
            
            
            <b-table-column field="days" label="days" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_codes_day7 ? 'Ma' : '' }}
                {{ props.row.wa_codes_day1 ? 'Di' : '' }}
                {{ props.row.wa_codes_day2 ? 'Wo' : '' }}
                {{ props.row.wa_codes_day3 ? 'Do' : '' }}
                {{ props.row.wa_codes_day4 ? 'Vr' : '' }}
                {{ props.row.wa_codes_day5 ? 'Za' : '' }}
                {{ props.row.wa_codes_day6 ? 'Zo' : '' }}
                
            </b-table-column>
            <b-table-column field="department_id" label="department" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.department_id }} - {{ props.row.department_name ? props.row.department_name : '' }}
            </b-table-column>
            
            <b-table-column field="wa_code_schedule_grouped_code" label="show" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered>
                <b-button 
                  @click="toggleShowCodeSchedule(props.row.wa_code_schedule_id)"
                  :type="codesFilterStatus[props.row.wa_code_schedule_id].show === true ? 'is-success' : ''"
                  >
                  {{  codesFilterStatus[props.row.wa_code_schedule_id].show === true ? 'yes' : 'no' }}
                </b-button>
            </b-table-column>
            
            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Vacancies</h1>
        <b-button :type="this.filterStatus === '' ? 'is-success': ''" @click="showStatus('')">All</b-button>
        <b-button :type="this.filterStatus === 'assigned' ? 'is-success': ''" @click="showStatus('assigned')">Assigned</b-button>
        <b-button :type="this.filterStatus === 'open' ? 'is-success': ''" @click="showStatus('open')">Open</b-button>
        <b-select v-model="vacancyDataIsPaginatedPerPage">
            <option value="50">50 per page</option>
            <option value="250">250 per page</option>
            <option value="500">500 per page</option>
            <option value="2000">2000 per page</option>
            <option value="10000">10000 per page</option>
        </b-select>
        <b-table
            :data="vacancyDatafiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableVacancyIsLoading"
            :paginated="vacancyDataIsPaginated"
            :per-page="vacancyDataIsPaginatedPerPage"
            :current-page.sync="vacancyDataCurrentPage"
            :mobile-cards="true">

            <b-table-column field="scheduleId" label="scheduleId" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.scheduleId }}
            </b-table-column>
            
            <b-table-column field="shiftId" label="shiftId" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.shiftId }}
            </b-table-column>

            <b-table-column field="vacancyId" label="vacancyId" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              <a :href='`${WEB_ROOT}/company/${companyId}/vacancy/${props.row.vacancyId}`' target='_blank' class="highlight">
                {{ props.row.vacancyId }}
              </a>
            </b-table-column>

            <b-table-column field="datetime" label="date" width="100" :td-attrs="columnTdAttrs" v-slot="props" sortable centered>
                {{ props.row.date }}
            </b-table-column>
            
            <!-- <b-table-column field="datetime" label="datetime" width="80" :td-attrs="columnTdAttrs" v-slot="props" number sortable centered searchable>
                {{ props.row.datetime }}
            </b-table-column> -->
            
            <b-table-column field="dateStr" label="date Str" width="120" :td-attrs="columnTdAttrs" v-slot="props" number sortable centered searchable>
                {{ props.row.dateStr }}
            </b-table-column>
                        
            <b-table-column field="start" label="start" width="40" :td-attrs="columnTdAttrs" date v-slot="props" sortable centered searchable>
                {{ props.row.start }}
            </b-table-column>
            
            <b-table-column field="end" label="end" width="40" :td-attrs="columnTdAttrs" date v-slot="props" sortable centered searchable>
                {{ props.row.end }}
            </b-table-column>
            
            <b-table-column field="duration" label="duration" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" centered sortable searchable>
                {{ roundMaxDigits(props.row.duration, 2) }}
            </b-table-column>

            <b-table-column field="price" label="price" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.price }}
            </b-table-column>
            
            <b-table-column field="HolidayName" label="holiday name" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.HolidayName }}
            </b-table-column>
            
            <b-table-column field="hourlyFinalPrice" label="hourly final price" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.hourlyFinalPrice }}
            </b-table-column>
            
            <b-table-column field="totalValue" label="total value" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.totalValue.toFixed(2) }}
            </b-table-column>
            
            <b-table-column field="isHoliday" label="is holiday" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.isHoliday }}
            </b-table-column>
            
            <b-table-column field="codeId" label="code Id" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.codeId }}
            </b-table-column>
            <b-table-column field="code" label="code" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.code }}
            </b-table-column>
            <b-table-column field="codetype" label="type" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.codetype }}
            </b-table-column>
            <b-table-column field="codename" label="code name" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.codename }}
            </b-table-column>            
            <b-table-column field="exclude_quotum" label="exclude quotum" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.exclude_quotum }}
            </b-table-column>
            <b-table-column field="quotem_count" label="quotum value" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.quotem_count }}
            </b-table-column>
            <b-table-column field="vacancyStatus" label="status" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.vacancyStatus }}
            </b-table-column>
            <b-table-column field="user_id" label="assigned userId" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              <a :href='`${WEB_ROOT}/company/${companyId}/user/${props.row.user_id}`' target='_blank' class="highlight">
                {{ props.row.user_id }}
              </a>
            </b-table-column>            
            <b-table-column field="roles" label="roles" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.roles }}
            </b-table-column>            
            <!-- <b-table-column field="response_statuses_updated_at" label="response date" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.response_statuses_updated_at ? DDMMYYYY_HHii(new Date(props.row.response_statuses_updated_at)) : '' }}
            </b-table-column>             -->
            <b-table-column field="users_name" label="assigned user name" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.users_name }}
            </b-table-column>            
            <b-table-column field="users_email" label="users email" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.users_email }}
            </b-table-column>            
            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
        
        
        <div class="columns is-multiline is-marginless">
            <div class="column is-12">Totals</div>
            <div class="column is-2">count: {{ vacanciesTotals.count }}</div>
            <div class="column is-2">total duration hours: {{ vacanciesTotals.duration.toFixed(2) }}</div>
            <div class="column is-2">open count: {{ vacanciesTotals.open }}</div>
            <div class="column is-2">open hours: {{ vacanciesTotals.openHours.toFixed(2) }}</div>
            <div class="column is-2">assigned count: {{ vacanciesTotals.assigned }}</div>
            <div class="column is-2">assigned hours: {{ vacanciesTotals.assignedHours.toFixed(2) }}</div>
        </div>
          
        
      </div>
      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
import { waitTime }         from '@/helpers/functions';
import { roundMaxDigits }         from '@/helpers/numbers';
// import { mapState } from 'vuex';

import { DDMMYYYY, DDMMYYYY_HHii } from '@/helpers/dates.js';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';
     
    export default {
      data() {
        
        return {
          companyId           : null,
          companyData         : null,
          scheduleData        : [],
          
          periods               : [],
          periodFilteredSet     : new Set([]),
          
          scheduleDataFiltered  : [],
          scheduleDataFilteredSet  : new Set([]),
          
          tableScheduleIsLoading  : false,
          
          showHolidayData       : false,
          holidayData           : [],
          holidayDataIsPaginated: true,
          holidayDataIsPaginatedPerPage:50,
          holidayDataCurrentPage: 1,
          tableHolidaysIsLoading: false,
          
          vacancyData           : [],
          vacancyDatafiltered   : [],
          vacancyDataIsPaginated: true,
          vacancyDataIsPaginatedPerPage:50,
          vacancyDataCurrentPage: 1,
          
          showRolesTable        : false,
          rolesSummaryData      : [],
          companyRoleData       : [],
          companyRolesButtons   : [],
          roleDataFiltered      : [],          
          rolesColumnData       : [],
          tableRolesIsLoading   : false,
         
          tableVacancyIsLoading : false,
          
          showCodesTable        : true,
          
          codesData             : [],
          codesDataFiltered     : [],
          
          codesFilterStatus     : {},
          filterStatus          : '',
          tableCodesIsLoading   : false,
          
          vacanciesTotals : {
            count: 0,
            duration: 0,
            open: 0,
            openHours: 0,
            assigned: 0,
            assignedHours: 0,
          },
          
          WEB_ROOT  : WEB_ROOT,
          SITE_ROOT : process.env.VUE_APP_SITE_MAIN,
          API_ROOT: API_ROOT,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.ShowPageLoading(15000)
        await this.getCompanyData()
        await this.getRolesData();
      },
      methods: {
        DDMMYYYY_HHii,DDMMYYYY, roundMaxDigits,
        
        async getCompanyData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.companyData  = response.data.payload.companyData
          this.codesData     = response.data.payload.codesData
          // console.info(`this.codeData:`, this.codesData)
          for(let code of this.codesData) {
            this.codesFilterStatus[code.wa_code_schedule_id] = {
              show: false,
              id: code.wa_codes_id,
              // code: code.wa_codes_code,
              scheduleId: code.wa_code_schedule_schedule_id,
              codeScheduleId: code.wa_code_schedule_id,
              codeId: code.wa_codes_id,
              name: code.wa_codes_name,
            }
            // if (code.wa_code_schedule_code_id === 56) {
            //   this.codesFilterStatus[code.wa_code_schedule_code_id].show = true
            // }
          }
          
          this.scheduleData = response.data.payload.scheduleData          
          this.vacancyData  = response.data.payload.vacancyData
          this.holidayData  = response.data.payload.holidayData
          let maxPeriodId = 0
          response.data.payload.periods.forEach(p => { maxPeriodId = Math.max(maxPeriodId, p.company_periods_id)})
          console.info(`maxPeriodId:`, maxPeriodId)
          this.periods      = response.data.payload.periods.map(p => {
            p.isSelected = p.company_periods_id === maxPeriodId
            return p
          })
          this.periodFilteredSet.add(maxPeriodId)
          // console.info(`this.periods:`, this.periods)
          
          this.filterSchedules()          
          this.HidePageLoading()
        },
        filterSchedules() {
          console.info(`this.periodFilteredSet:`, this.periodFilteredSet)
          let schedules = []
          let schedulesSet = new Set([])
          for(let schedule of this.scheduleData) {
            if (this.periodFilteredSet.size === 0) {
              schedules.push(schedule)
              schedulesSet.add(schedule.id)
              continue
            }
            if (this.periodFilteredSet.has(schedule.company_period_id)) {
              schedulesSet.add(schedule.id)
              schedules.push(schedule)
              continue
            }
          }
          this.scheduleDataFiltered = schedules
          console.info(`scheduleDataFiltered:`, this.scheduleDataFiltered)
          this.scheduleDataFilteredSet = schedulesSet
          console.info(`scheduleDataFilteredSet:`, this.scheduleDataFilteredSet)
          this.filterCodes()
          this.filterScheduleData()
        },
        toggleFilterPeriod(periodId){
          if (this.periodFilteredSet.has(periodId)) {
            this.periodFilteredSet.delete(periodId)
          } else {
            this.periodFilteredSet.add(periodId)
          }
          this.filterSchedules()
          this.filterCodes()
        },
        filterCodes(){
          console.info(`this.codesData:`, this.codesData)
          let codesFiltered = []
          for(let code of this.codesData) {
            // console.info(`==>scheduleDataFilteredSet:`, this.scheduleDataFilteredSet)
            // console.info(`==>code:`, code)
            // return
            if (this.scheduleDataFilteredSet.size === 0) {
              codesFiltered.push(code)
              continue
            }
            if (this.scheduleDataFilteredSet.has(code.wa_code_schedule_schedule_id)) {
              codesFiltered.push(code)
              continue
            }
          }
          this.codesDataFiltered = codesFiltered
          console.info(`==>codesDataFiltered:`, this.codesDataFiltered)
          this.clearCodesFilterStatus()
        },
        clearCodesFilterStatus(){
          for(let key in this.codesFilterStatus) {
            let codeFilter = this.codesFilterStatus[key]
            codeFilter.show = false
          }
        },
        
        async getRolesData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/roles`, {});
          console.info(`response:`, response)
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company role data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          
          this.rolesSummaryData = response.data.payload.Sumary
          console.info(`this.rolesSummaryData:`, this.rolesSummaryData)
          this.companyRoleData = response.data.payload.companyroles   
          console.info(`this.companyRoleData:`, this.companyRoleData)
          
          let companyRoles = [] 
          for(let roles of this.companyRoleData) {
            companyRoles.push({
              id: roles.roles_id,
              name: roles.roles_name,
              show: roles.roles_name !== 'CompanyOwner' && roles.roles_name !== 'CompanyManager' ? true : false
            })
          }
          this.companyRolesButtons = companyRoles
          console.info(`this.companyRoles:`, this.companyRolesButtons)
          this.filterCompanyRoles()
        },
        
        filterCompanyRoles() {          
          let RoleList = []
          let showList = {}
          for(let showRoleBtn of this.companyRolesButtons){            
            showList[showRoleBtn.name] = showRoleBtn.show 
          }
          
          for(let permission in this.rolesSummaryData) {
            let permissionRoleList = this.rolesSummaryData[permission]
            let row = {}
            row.name = permission
            
            let hasItem = false
            for(let role of this.companyRoleData) {
              row[role.roles_name] = ''
              if (showList[role.roles_name] !== true) continue              
              
              for(let key in permissionRoleList) {
                if (key === role.roles_name) {
                  row[role.roles_name] = 'X'
                  hasItem = true
                }
              }
            }
            
            if (hasItem) {
              RoleList.push(row)
            }
          }
          
          let rolesColumnData = [{ field: 'name', label: 'permission', width: 40, searchable: true, centered: false, sortable: true }]
          for(let role of this.companyRoleData) {
            if (showList[role.roles_name] !== true) continue            
            rolesColumnData.push({ field: role.roles_name, label: role.roles_name, width: 40, searchable: true, centered: true, sortable: true })
          }
          
          this.roleDataFiltered = RoleList
          this.rolesColumnData = rolesColumnData
          
          console.info(`RoleList:`, RoleList)  
        },
        
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        toggleShowRoleStatus(role) {
          role.show = !role.show;
          this.filterCompanyRoles()
        },
        
        toggleShowCodeSchedule(codeScheduleId) {
          this.codesFilterStatus[codeScheduleId].show = !this.codesFilterStatus[codeScheduleId].show
          this.filterScheduleData()
        },
        toggleShowAllScheduleCodes(scheduleId, showValue = true) {
          // this.codesFilterStatus[codeScheduleId].show = !this.codesFilterStatus[codeScheduleId].show
          for(let codeScheduleId in this.codesFilterStatus) {
            const codeSchedule = this.codesFilterStatus[codeScheduleId]
            if (codeSchedule.scheduleId === scheduleId) {
              codeSchedule.show = showValue
            }
          }
          this.filterScheduleData()
        },
        
        async showAllCodes(){
          this.ShowPageLoading(5000); await waitTime(200)
          for(let key in this.codesFilterStatus) {
            if (this.scheduleDataFilteredSet.size === 0 || this.scheduleDataFilteredSet.has(this.codesFilterStatus[key].scheduleId)) { 
              this.codesFilterStatus[key].show = true;
            }
          }
          this.filterScheduleData()
        },
        async hideAllCodes(){
          this.ShowPageLoading(5000); await waitTime(200)
          for(let key in this.codesFilterStatus) {
            this.codesFilterStatus[key].show = false;            
          }
          this.filterScheduleData()
        },
        async showStatus(status){
          this.ShowPageLoading(5000); await waitTime(200)
          this.filterStatus = status;
          this.filterScheduleData()
        },
        
        filterScheduleData(){
          this.ShowPageLoading(5000)
          let totals = {
            count: 0,
            duration: 0,
            open: 0,
            openHours: 0,
            assigned: 0,
            assignedHours: 0,
          }
          
          let list = []
          for(let vacancy of this.vacancyData) {
            // console.info(`vacancy:`, vacancy)
            if (this.codesFilterStatus[vacancy.codeScheduleId].show !== true) {
              continue
            }
            
            if (this.filterStatus === "open" && (vacancy.status === "assigned" || vacancy.status === "delegated")) {
              continue
            }
            
            if (this.filterStatus === "assigned" && (vacancy.status !== "assigned" && vacancy.status !== "delegated")) {
              continue
            }
            
            
            totals.count += 1
            totals.duration += vacancy.duration            
            if (vacancy.status !== "assigned" && vacancy.status !== "delegated") {
              totals.open += 1  
              totals.openHours += vacancy.duration 
            }
            if (vacancy.status === "assigned" || vacancy.status === "delegated") {
              totals.assigned += 1  
              totals.assignedHours += vacancy.duration  
            }
            list.push(vacancy)
          }
          
          this.vacancyDatafiltered = list     
          this.vacanciesTotals = totals   
          this.vacancyDataCurrentPage = 1
          // console.info(`this.vacancyDatafiltered:`, this.vacancyDatafiltered)
          this.HidePageLoading(5000)
        },
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
        
        DoubleDigits(){
          if (parseInt(number) < 10) {
            return "0" + number;
          }
          return number;
        },
        TimeStampToDate(timestamp, startTime){
          let d = new Date()
          d.setTime(timestamp)
          
          return `${this.DoubleDigits(d.getDate())}-${this.DoubleDigits(d.getMonth() +1)}-${d.getFullYear()} ${startTime}`
        }
      }
    }
</script>

<style scoped>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
</style>